import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { dateFormat } from "../helpers";
import { Loading } from "../Components/Loading";
import { SearchForm } from "../Components/SearchForm";
import "../App.css";

@inject("AppStore")
@observer
class Builds extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { location } = this.props.match.params;

    this.store.getLocation(location);
    this.store.loadConfig();

    const { user } = this.store;
    if (!user) window.location.href = "/";

    this.store.getBuilds();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.page.lang}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {this.store.configuration && this.store.configuration.logo && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </a>
          </div>
          {this.store.user ? (
            <div className="header-user">
              <span>{this.store.user.user.fullname} -&nbsp;</span>
              <a onClick={this.store.logout} href="/">
                LOGOUT
              </a>
            </div>
          ) : (
            <div className="header-user">
              <a href={`/account`}>Account</a>
            </div>
          )}
        </div>
        <div className={`content`} style={{ flexDirection: "column" }}>
          <div className="modelSelector__filter">
            <div style={{ padding: 20, background: "#fff" }}>
              <SearchForm
                onFilterBuilds={(text) => {
                  this.store.onFilterBuilds(text);
                }}
              />

              <div className="buttons">
                <button
                  className={this.store.selectedStatus === "" ? "active" : ""}
                  onClick={(e) => (this.store.selectedStatus = "")}
                >
                  All
                </button>
                <button
                  className={
                    this.store.selectedStatus === "waiting" ? "active" : ""
                  }
                  onClick={(e) => (this.store.selectedStatus = "waiting")}
                >
                  Waiting
                </button>
                <button
                  className={
                    this.store.selectedStatus === "sold" ? "active" : ""
                  }
                  onClick={(e) => (this.store.selectedStatus = "sold")}
                >
                  Sold
                </button>
                <button
                  className={
                    this.store.selectedStatus === "canceled" ? "active" : ""
                  }
                  onClick={(e) => (this.store.selectedStatus = "canceled")}
                >
                  Canceled
                </button>
              </div>
            </div>
          </div>

          <div
            className="modelSelector__left"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: 50, paddingTop: 0 }}>
              {this.store.selectedStatus}
              <table className="order-table">
                <thead>
                  <tr>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) =>
                          this.store.onSortBuilds(e, "order_number")
                        }
                      >
                        Order#
                        {this.store.builds_sort === "order_number" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) => this.store.onSortBuilds(e, "date")}
                      >
                        Date
                        {this.store.builds_sort === "date" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) => this.store.onSortBuilds(e, "firstname")}
                      >
                        Customer
                        {this.store.builds_sort === "firstname" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>
                      <a
                        className="sort"
                        onClick={(e) =>
                          this.store.onSortBuilds(e, "model_slug")
                        }
                      >
                        Model
                        {this.store.builds_sort === "model_slug" &&
                          (this.store.builds_sort_direction === "asc" ? (
                            <i className="fa-solid fa-chevron-up"></i>
                          ) : (
                            <i className="fa-solid fa-chevron-down"></i>
                          ))}
                      </a>
                    </th>
                    <th>Status</th>
                    <th>Service</th>
                    <th>Url</th>
                  </tr>
                </thead>
                <tbody>
                  {this.store.filtered_builds.map(
                    (b, i) =>
                      b.model_slug &&
                      (this.store.selectedStatus === "" ||
                        this.store.selectedStatus === b.status) && (
                        <tr key={i} data-boat={JSON.stringify(b)}>
                          <td>{b.order_number}</td>
                          <td>{dateFormat(b.date)}</td>
                          <td>
                            {b.firstname} {b.lastname}
                          </td>
                          <td>{b.model_slug}</td>
                          <td>
                            <select
                              onChange={(e) =>
                                this.store.onStatusChanged(b.id, e.target.value)
                              }
                            >
                              <option
                                value="waiting"
                                selected={b.status === "waiting"}
                              >
                                Bekliyor
                              </option>
                              <option
                                value="sold"
                                selected={b.status === "sold"}
                              >
                                Satıldı
                              </option>
                              <option
                                value="canceled"
                                selected={b.status === "canceled"}
                              >
                                İptal Oldu
                              </option>
                              <option
                                value="passive"
                                selected={b.status === "passive"}
                              >
                                Pasif
                              </option>
                              <option
                                value="active"
                                selected={b.status === "active"}
                              >
                                Aktif
                              </option>
                              <option
                                value="pending_agreement"
                                selected={b.status === "pending_agreement"}
                              >
                                Sözleşme Aşamasında
                              </option>
                              <option
                                value="pending_payment"
                                selected={b.status === "pending_payment"}
                              >
                                Ödeme Bekleniyor
                              </option>
                            </select>
                          </td>
                          <td>
                            <button
                              onClick={() => this.store.sendToDock(b.id)}
                              disabled={this.store.serviceLoading}
                              style={{
                                backgroundColor: "#4CAF50",
                                color: "#fff",
                                border: "none",
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "5px",
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              CRM'e Aktar
                            </button>

                            {this.store.serviceMessage &&
                              this.store.serviceId === b.id && (
                                <>
                                  {this.store.serviceLoading && (
                                    <div>Loading</div>
                                  )}
                                  <div
                                    style={{
                                      fontSize: 12,
                                      marginTop: 4,
                                      textAlign: "center",
                                      color: this.store.serviceSuccess
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {this.store.serviceMessage}
                                  </div>
                                </>
                              )}
                          </td>
                          <td>
                            <a
                              href={`/en/build/${b.model_slug}/${b.order_number}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "inline-block",
                                backgroundColor: "indianred",
                                color: "#fff",
                                border: "none",
                                padding: "10px",
                                cursor: "pointer",
                                borderRadius: "5px",
                                textDecoration: "none",
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              Order Form
                            </a>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Builds;
